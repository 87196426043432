import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from '../assets/images/Events/Banner.webp';
import Event1Img1 from '../assets/images/Events/C1/Event1Img1.jpeg';
import Event1Img2 from '../assets/images/Events/C1/Event1Img2.jpeg';
import Event1Img3 from '../assets/images/Events/C1/Event1Img3.jpeg';
import Event1Img4 from '../assets/images/Events/C1/Event1Img4.jpg';
import Event2Img1 from '../assets/images/Events/C2/Event2Img1.jpeg';
import Event2Img2 from '../assets/images/Events/C2/Event2Img2.jpeg';
import Event2Img3 from '../assets/images/Events/C2/Event2Img3.jpeg';
import Event3Img1 from '../assets/images/Events/C3/Event3Img1.jpeg';
import Event3Img2 from '../assets/images/Events/C3/Event3Img2.jpeg';
import Event3Img3 from '../assets/images/Events/C3/Event3Img3.jpeg';
import Event3Img4 from '../assets/images/Events/C3/Event3Img4.jpeg';
import Event4Img1 from '../assets/images/Events/C4/Event4Img1.jpg';
import Event5Img1 from '../assets/images/Events/C5/Event5Img1.jpeg';
import Event5Img2 from '../assets/images/Events/C5/Event5Img2.jpeg';
import Event5Img3 from '../assets/images/Events/C5/Event5Img3.jpeg';
import Event5Img4 from '../assets/images/Events/C5/Event5Img4.jpeg';
import Event7Img1 from '../assets/images/Events//C7/Event7Img1.jpeg';
import Event7Img2 from '../assets/images/Events//C7/Event7Img2.jpeg';
import Event7Img3 from '../assets/images/Events//C7/Event7Img3.jpeg';
import Event7Img4 from '../assets/images/Events//C7/Event7Img4.jpeg';
import Event8Img1 from '../assets/images/Events/C8/22jan-img1.jpg';
import Event8Img2 from '../assets/images/Events/C8/22jan-img2.jpg';
import Event8Img3 from '../assets/images/Events/C8/22jan-img3.jpg';
import Event8Img4 from '../assets/images/Events/C8/22jan-img4.jpg';
import Event9Img1 from '../assets/images/Events/C9/Congressman-August-Pfluger-TX.jpg';
import Event10Img1 from '../assets/images/Events/C10/Congressman-Greg-Casar-TX.jpg';
import Event11Img1 from '../assets/images/Events/C11/Congressman-Frank-Pallone-NJ.jpg';
import Event12Img1 from '../assets/images/Events/C12/Congressman-Jim-McGovern.jpg';
import Event13Img1 from '../assets/images/Events/C13/Congresswoman-Rashida-Tlaib.jpg';

import InitiativesCommon from '../components/common/InitiativesCommon';

const Events = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>First Pakistan Global</title>
      </Helmet>
      <Header />
      <section id="about-page">
        <div className="text-center">
          <div className="bg-common py-5" style={{ backgroundImage: `url(${Banner})` }}>
            <div className='text-white fs-ss-68 py-4 py-md-5 my-md-5'>
              <div className='fw-700 text-uppercase'>
                <div className="fw-700">
                  Events
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-4 py-md-5" />
        <section className="container-fluid">
          <div className="row justify-content-center gy-5">

            <InitiativesCommon
              Id="13"
              ImageDisplay="d-none"
              VideoDisplay="d-block"
              ImgCol="col-11 col-md-10"
              Date={<> 22 <br className='d-none d-md-block' /> Jan <br className='d-none d-md-block' /> 2025 </>}
              Images={[Event13Img1]}
              VideoLink="https://drive.google.com/file/d/1CVAyiOYfOLn38vpukzy4u6leFu2IBdC4/preview"
              Title={<>The Right to Vote</>}
              SubTitle="Congressman Rashida Tlaib, MI"
              Para="“The right to vote is sacred. I come here in solidarity with Pakistani people. We stand here to free Imran Khan. Pakistani people should be able to choose their own political leaders.”"
              />

            <InitiativesCommon
              Id="12"
              ImageDisplay="d-none"
              VideoDisplay="d-block"
              ImgCol="col-11 col-md-10"
              Date={<> 22 <br className='d-none d-md-block' /> Jan <br className='d-none d-md-block' /> 2025 </>}
              Images={[Event12Img1]}
              VideoLink="https://drive.google.com/file/d/1itIdw9dmuwm7e2aL3TRYMH3bFqGdZ0TN/preview"
              Title={<>Struggle for Democracy</>}
              SubTitle="Congressman Jim McGovern, MA"
              Para="“I stand in solidarity with the Pakistani people who are engaged in their struggle for their own democracy. What happened in November was unacceptable. Internet shutdown, mass arbitrary detentions, deployment of the military is never an appropriate response to people exercising their right for peaceful assembly.” 
              “I stand with Amnesty International calling for urgent, thorough and transparent investigation into the government’s crackdown on opposition protestors.”"
              />

            <InitiativesCommon
              Id="11"
              ImageDisplay="d-none"
              VideoDisplay="d-block"
              ImgCol="col-11 col-md-10"
              Date={<> 22 <br className='d-none d-md-block' /> Jan <br className='d-none d-md-block' /> 2025 </>}
              Images={[Event11Img1]}
              VideoLink="https://drive.google.com/file/d/1OPBGMDskv7eDIWMJ8reQJWw_Dpd9-C0k/preview"
              Title={<>Free and Fair Elections</>}
              SubTitle="Congressman Frank Pallone, NJ"
              Para="“What happened in February 2024 elections was very upsetting for me. United States has an obligation to continue to speak out in support of free and fair elections and freedom of speech in Pakistan and throughout the world.”"
              />

            <InitiativesCommon
              Id="10"
              ImageDisplay="d-none"
              VideoDisplay="d-block"
              ImgCol="col-11 col-md-10"
              Date={<> 22 <br className='d-none d-md-block' /> Jan <br className='d-none d-md-block' /> 2025 </>}
              Images={[Event10Img1]}
              VideoLink="https://drive.google.com/file/d/1TMBG-5TeTzqsC9CxN9jvhvQUQq3efwqW/preview"
              Title={<>The Fear of Oppression</>}
              SubTitle="Congressman Greg Casar, TX"
              Para="“The question is: can the people of Pakistan choose their own path, choose their own future? Can they choose that future and be able to protest, demonstrate, vote whomever they like without the fear of oppression.”"
              />

            <InitiativesCommon
              Id="9"
              ImageDisplay="d-none"
              VideoDisplay="d-block"
              ImgCol="col-11 col-md-10"
              Date={<> 22 <br className='d-none d-md-block' /> Jan <br className='d-none d-md-block' /> 2025 </>}
              Images={[Event9Img1]}
              VideoLink="https://drive.google.com/file/d/18RY8BUtuZisMoZimYf_nsTqnP123MwTe/preview"
              // VideoLink="<iframe src="https://drive.google.com/file/d/18RY8BUtuZisMoZimYf_nsTqnP123MwTe/preview" width="640" height="480" allow="autoplay"></iframe>"
              Title={<>The US Commitment</>}
              SubTitle="Congressman August Pfluger, TX"
              Para="“Countries relationships grow stronger when we abide by values with regards to human rights, with regards to political stability.” 
              “I look forward to working with the incoming Trump Administration to reaffirm the US commitment to the Pakistani-American community to pursue a free, fair and democratic Pakistan and to usher in a new era of U.S.-Pakistan relationship.”"
              // Para={<> 
              //     <p> “Countries relationships grow stronger when we abide by values with regards to human rights, with regards to political stability.” </p>
                  
              //     <p> Travel bans, visa restrictions, and asset freezes for perpetrators of crimes against humanity.  </p>
              //   </>}
              />


            

          <InitiativesCommon
              Id="8"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 22 <br className='d-none d-md-block' /> Jan <br className='d-none d-md-block' /> 2025 </>}
              Images={[Event8Img1, Event8Img2, Event8Img3, Event8Img4]}
              Title="Congressional Briefing"
              SubTitle=""
              Para="On January 22, 2025, First Pakistan Global, Congressman Greg Casar's office, and coalition organizations hosted a pivotal Congressional."
            />

          <InitiativesCommon
              Id="1"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 2024 </>}
              Images={[Event1Img1, Event1Img2, Event1Img3, Event1Img3]}
              Title="Human Rights Summit at Capitol Hill"
              SubTitle=""
              Para="Human Rights Conference with Congressman Greg Casar and Congresswoman Abigail Spanberger."
            />

            <InitiativesCommon
              Id="2"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 29 <br className='d-none d-md-block' /> Sep <br className='d-none d-md-block' /> 2024 </>}
              Images={[Event2Img1, Event2Img2, Event2Img3, Event3Img4]}
              Title="Conference at University of Central Florida (UCF)"
              SubTitle="FPG supported other diaspora organizations to initiate the letter"
              Para="To address human rights violations & political instability in Pakistan, September 29, 2024. First Pakistan Global and Pakistani-American community held a conference at University of Central Florida (UCF) to address human rights violations and political instability in Pakistan.  A poll showed 63% of attendees held General Asim Munir responsible for the socio-economic and political crisis in Pakistan, demanding his resignation for violating his oath.  "
            />

            <InitiativesCommon
              Id="4"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 03 <br className='d-none d-md-block' /> July <br className='d-none d-md-block' /> 2024 </>}
              Images={[Event4Img1]}
              Title="Global Conference on Human Rights"
              SubTitle=""
              Para="FPG in collaboration with Friends of Democratic Pakistan held Global Conference on Human Rights and Diaspora’s role in demanding democracy in Pakistan–Gaylord Convention Center, DC, USA."
            />

            <InitiativesCommon
              Id="3"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 14 <br className='d-none d-md-block' /> March <br className='d-none d-md-block' /> 2024 </>}
              Images={[Event3Img1, Event3Img2, Event3Img3, Event3Img4]}
              Title="IMF Protest"
              SubTitle=""
              Para="FPG, in collaboration with the Pakistani-American diaspora organized the protest in front of the IMF demanding independent and transparent investigation by International Observers and fact finding committee to any loan disbursement to the illegitimate regime in Pakistan."
            />  
            
            {/* <InitiativesCommon
              Id="5"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 2024 </>}
              Images={[Event5Img1, Event5Img2, Event5Img3, Event5Img4]}
              Title="George Washington University Conference"
              SubTitle=""
              Para=""
            />  */}

            <InitiativesCommon
              Id="7"
              ImageDisplay="d-block"
              VideoDisplay="d-none"
              ImgCol="col-11 col-md-10"
              Date={<> 02 <br className='d-none d-md-block' /> Nov <br className='d-none d-md-block' /> 2023 </>}
              Images={[Event7Img1, Event7Img2, Event7Img3, Event7Img4]}
              Title="Hunger Strike event at the State Dept"
              SubTitle=""
              Para=""
            /> 
          </div>
        </section>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default Events;
